.search-container{
    display: flex;
    background: #fff;
    padding: 10px;
    width: 90%;
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    max-width: 98%;

    

    .search-button{
      margin: auto 10px auto 0;
      // padding-right: 10px;
      cursor: pointer;
      
    }

    .search-text {
      border: none;
      background: transparent;
      transition: 0.2s ease-in;
      width: stretch;
      &:focus{
        padding-left: 10px;
        outline: none;
      }
    }

    .clear-icon{
      margin-top: 2px;
      cursor: pointer;
      &:hover{

      }
    }
  }

  @media only screen and (max-width: 768px) {
    .search-container {
      margin-bottom: 10px;
    }
  }