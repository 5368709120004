#footnotes-check {
  float: right;
}

.footnotes {
  display: flex;
  a {
    color: blue;
  }
  ul {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    list-style-type: none;

    p {
      // span {
      //   display: inline-block;
      //   width: 10px;
      //   height: 10px;
      // }

      margin: 0;
      font-size: 0.95rem;
    }

    &.horizontal {
      li {
        display: inline;
        margin: 0 5px;

        &:first-child {
          margin-left: 0;
        }

        p {
          display: inline;
        }
      }
    }
  }
}
