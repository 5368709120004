@import "../config";

#report-4 {
  thead {
    color: white;
  }

  th {
    font-weight: initial;
    // min-height: 3rem;
    height: 2rem;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #3f3f3f;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    // width: 18%;

    &.month-headers {
      // min-width: 100px;
      // max-width: 100px;
      width: 9%;
      text-align: center;
    }
  }
  th:first-child {
    background-color: #fff;
  }

  td {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    border: 1px solid #ddd;

    &.conference {
      background-color: #ddd;
      text-transform: uppercase;
    }

    &.thirdCol span {
      display: block;

      &:first-child {
        font-weight: bold;
        display: flex;
        justify-content: center;

        span.footnotesIndex {
          // color: red;
          display: inline-block;
          // font-size: 0.7rem;
          font-weight: normal;
          // margin-top: -3px;

          span {
            display: inline-block;
          }
        }
      }
    }

    &.segmentTd {
      // max-width: 40px;
      padding: 10px 10px 0 10px;

      div{
        // width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      p {
        margin: auto;
        // writing-mode: tb-rl;
        transform: rotate(270deg);
        font-weight: bold;
        font-style: italic;
        text-align: center;
      }

      span {
        transform: rotate(-90deg);
        display: block;
        line-height: 0.7rem;
        // padding: 0 5px;
      }
    }

    &.event {
      background-color: #f4cccc;
      font-weight: bold;
      padding: 0.3rem;
      // font-size: 0.85rem;
    }

    &.event-continue-line {
      // background-color: red;
      position: relative;

      &::after {
        content: "-----------------";
        color: #3f3f3f;
        font-size: 1rem;
        letter-spacing: 5px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        height: 5px;
        line-height: 2px;
        // border: 1.5px;
        // border-style: dashed;
        // border-color: #ddd;
        position: absolute;
        top: calc(50% - 2.5px);
        left: 0;
      }

      &.end::before {
        // background-color: red;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        top: calc(50% - 9px);
        right: -8px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid transparent;
        border-left: 12px solid #696969;
      }
    }

    &.companyLogos {
      padding: 0 0.5rem;

      img {
        max-width: 75px;
        margin: 5px 0;
      }
      span {
        display: block;
        margin: 5px 0;
      }
    }
  }

  table {
    border-spacing: 0;
    // margin-left: 10px;
    width: 100%;
    font-size: 1.20em;
  }

  tbody tr {
    background-color: #fff;
  }

  .bg-blue {
    background-color: #002060;
  }

  .bg-red {
    background-color: #c00000;
  }

  .bg-white {
    background-color: white;
  }

  td.center {
    text-align: center;
  }

  .actionBtn {
    display: flexbox;
    text-align: right;
  }
  .actionBtn div {
    display: inline-flex;
  }
  .filterContainer {
    margin-left: 10px;

    label {margin-bottom: 0;}
  }

  #footnotes-check {
    float: right;
  }

  .footnotes {
    display: flex;
    justify-content: space-between;

    .eventTypes {
      min-width: 480px;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      align-content: flex-start;

      p {
        width: 150px;
        text-align: center;
        margin: 5px;
        padding: 5px;
        // font-size: 1.1rem;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .left-behind {
    table {
      th:first-child {
        background-color: #3f3f3f;
      }

      td {
        padding: 0;
      }
    }
  }
}
