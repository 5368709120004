@import '../config';



.advancedTitle {
  // font-size: 22px;
}
.headAdvance {
  background-color: #b22028;
  color: white;
  padding: 3px 20px;
}
.horizontal-line {
  width: 100%;
  height: 100px;
  border: 0;
  border-bottom: solid 1px grey;
  margin-bottom: 20px;
}

.filterActions{
  justify-content: center;
  padding: 20px 0;
}



button.filterButton {
  border: none;
  border-radius: 3px;
  padding: 5px 25px;
  border: 2px solid $app-red;
  text-transform: uppercase;
  color: $app-red;
  background-color: inherit;

  svg{
    display: inline-block;
  }

  &.red {
    color: white;
    background-color: $app-red;
  }
}

.filterModal{
  overflow-y: auto !important;
  max-width: 600px;
  max-height: 100vh;
  padding: 15px;

  .filterContainer{
    border: none;
    max-width: 100%;
    margin: 0;
    // overflow-y: scroll;
  }

  @include md{
    max-width: 90vw;
    height: auto;
    max-height: 100vh;
  }

  .MuiOutlinedInput-notchedOutline {
      border: 1px solid #DDDADA;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      // border-color: rgba(150, 82, 82, 0.87);
      border: 1px solid #DDDADA;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      // border-color: rgba(150, 82, 82, 0.87);
      border: 1px solid #DDDADA;
  }
}

// .closeAdvance button {
//   background-color: transparent;
//   color: white;
//   width: 26px;
//   height: 26px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 60px;
//   border: solid 1px white;
//   padding-top: 2px;
// }
// .closeAdvance button :only-child {
//   margin-top: 2px;
// }
