@import '../../config';


.section-header{
    padding-bottom: 20px;

    .section-left {
        display: flex;
        align-items: center;   
    }

    .section-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;   
    }
}


.layout-toolbar {
    padding: 15px 0;
}

.toolbar-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * {
        margin-left: 10px !important;
    }
}

.layout-content {
    padding: 20px 0;
}


.scrollable-nav {
    display: flex;
    align-items: center;
}
