@import '../../config';

.sources-box{
    border-bottom: 1px solid #ddd;
    padding: 5px;

    &>svg{
        margin: auto 3px;
        cursor: pointer;
    }
}

.sourceInputContainer{
    input{
        border: none;
        padding: 10px;
        font-family: 'Poppins';
        width: 100%;

        &::placeholder {
            color: '#938D8D'
        }
    }
}
