@import "../config";

.news-loader{
  width: 95%;
  margin-top: 20px;
  box-sizing: border-box;

  span{
    span{
      margin: 1%;
    }
  }
}

.newsContainer{
  margin-top: 15px;
}

.attachments-wrapper{
  margin: 12px;
  display: flex;
  flex-direction: row;
}

.attachment-wrapper{
  margin: 0 10px;
  width: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachment-icon{
  color: #8AC7CA;
  margin: 0 8px;
  transform: rotate(-45deg);
  font-size: 16px
}

.attachment-name{
  color: #8A8A8A;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
}

div.rowcard{
  background-color: white;
  box-shadow: black;
  width: 70%;
  position: relative;
  // border: solid 1px #bbb1b1;
  /* height: 30vh; */
  box-shadow: 0px 4px 4px rgba(217, 212, 212, 0.25);
  border-radius: 9px;
  margin-top: 20px;
  padding: 20px;
  font-size: 1rem;

  @include sm{
    font-size: 0.8rem;
  }
}

/*@media only screen and (max-width: 600px) {
  div.rowcard{
    width: 100%; 
  }
  .icon-title{
    display: none;
  }
}*/
div.rowcard{
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .news-date{
    display: none;
  }
  .card-footer-xs{
    display: block;
    padding-top: 20px;
  }
  .news-date-xs {
    color: #8b8b8b;
  }
}

@media only screen and (min-width: 600px) {
  .card-footer-xs{
    display: none;
  }
}

.datePicker{
  margin: 16px 0;
}

span.line{
  width: 95%;
  display: block;
  margin:14px 0;
  height: 1px;
  border-top: solid 1px #D8D0D0;
}

span.line-short{
  width: 15%;
  display: block;
  margin:14px 0;
  height: 1px;
  border-top: solid 1px #D8D0D0;
}

.icon-title-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
}

.icon-title{
  margin-right: 10px;
  cursor: pointer;
}
.attachments-legend{
font-family: Poppins;
font-weight: 500;
font-size: 14px;
line-height: 21px;
}
.comentary{
  margin: 10px 0;
  font-family: Poppins;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  color: #4D4F54;
  line-height: 24px;
}

.sources-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  text-align: center;
  background: #F4F4F4;
  border-radius: 5px;
}
.source-name{
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #B22028;
  text-decoration: underline;
  margin: 0 5px;
}
.news-date{
  font-family: Poppins;
  color: #323232;
  font-weight: 500;
  font-size: 14px;
  padding-top: 5px;
}
.sources-legend{
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4D4F54;
  margin: 0 10px;
}
.sourcelink-icon-wrapper{
  margin: 0 5px;
  flex-direction: row;
  width: 18%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-title{
  color: #4D4F54;
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;
  font-family: Poppins;
  margin-right: 10px!important;
}
.rowcard .second{
  color: #323232;
  div{
    font-weight: 100;
    font-weight: normal;
    width: 90%;
    line-height: 24px;
    @include sm{
      width: 100%;
    }
  }

  .indication{
    display: flex;
    margin-right: 5px;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    color: #8A8A8A;
    margin: 1rem 0;
    @include sm{
      margin: 0 1rem;
    }
  }

  .nct{
    color: $app-red;
    text-decoration: underline;
    font-weight: bolder;

    a:hover{
        color: inherit;
      }
  }

}

.diseases{
  font-weight: 600;
  color: #8A8A8A;
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
}
.compounds-companies-priority{
  font-family: Poppins;
  color: #8A8A8A;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.news-category{
  
  margin: 10px 0;

  h5{
    font-weight: bold;
    margin: 0;
    @include sm{
      font-size: 1rem;
    }
  }

}

.title-date-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content-comentary-hidden-wrapper{
  display: none;
}

.content-comentary-visible-wrapper{
  display: block;
}

.expand-icon-wapper{
  position: relative;
  margin-left: 34%;
  top: -21px;
}

.expand-icon{
  color: white;
  background-color: #CCC3C3;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: #B22028;
  }
}

.date-more-wrapper{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.compound-icon-wrapper {
  display: flex;
  margin-right: 5px;
  align-items: flex-end;
}

.seen-icon-wrapper{
  display: none;
  // display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #56C6CC;
}
.seen-icon-disabled-wrapper{
  display: none;
  // display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: center;
  color: #CCC3C3;
}

.fav-icon-wrapper{
  display: none;
  font-size: 16px;
  color: #B22028;
}

.fav-icon-disabled-wrapper{
  display: none;
  font-size: 16px;
  color: #CCC3C3;
}

.rdrDefinedRangesWrapper{
  width: auto;
}

.shownews{
  margin-top: 17px;

  p{
    margin: 0;
  }

  h1 {
    font-size: 24px;
  }
}

.link-icon{
  transform: rotate(-45deg);
  font-size: 16px;
}

.rowcard a.source_link{
  display: inline-block;
  // background-color: $bg-buttons;
  padding: 10px;
  border-radius: 5px;
  color: white;

  @include sm{
    padding: 5px;
  }
}

