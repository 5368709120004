@import "~bootstrap/dist/css/bootstrap.css";
@import "~react-date-range/dist/styles.css"; // main style file
@import "~react-date-range/dist/theme/default.css"; // theme css file

@font-face {
  font-family: "Calibri";
  src: url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.eot");
  src: url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/a78cfad3beb089a6ce86d4e280fa270b.svg#Calibri")
      format("svg");
}

@import './components/config';

body {
  margin: 0;
  padding: 0;
  color: $app-body-text;
  font-family: Calibri, 'Poppins' Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

button:focus{
  outline: none;
}

// typography
h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

.body-text1{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.body-text2{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}
