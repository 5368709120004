#addCompanyButton {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
}

#companyLogoManagerContainer {
  padding: 0 20px;
}

.logoCard {
  min-width: 196px;
  max-width: 290px;
  height: 120px;
}
