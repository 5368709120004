#filterButton span#counter {
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #fff;
  background-color: #b22028;
  border-radius: 50%;
  position: relative;
  right: -2px;
  top: -5px;
}

.date-picker-news {
  display: initial;
}