@import "../config";

#report-3 {
  thead {
    background-color: #3f3f3f;
    color: white;
  }

  th {
    font-weight: initial;
    min-height: 3rem;
    height: 3rem;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  td {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 3rem;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    border-collapse: collapse;
  }

  .left-behind {
    table {
      width: 100%;
    }

    th:last-child {
      text-align: center;
    }

    td {
      height: fit-content;
      padding: 0;
      text-align: center;
      border: 1px solid #ddd;
    }
  }

  table {
    border-spacing: 0;
    // margin-left: 10px;
    width: 100%;
    font-size: 1.15em;
  }

  tbody tr {
    background-color: #efefef;
  }

  .bg-blue {
    background-color: #002060;
  }

  .bg-red {
    background-color: #c00000;
  }

  .bg-white {
    background-color: white;
  }

  span.dot {
    height: 0.7rem;
    width: 0.7rem;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
  }

  .footnotesIndex {
    font-size: 0.7em;
  }

  td.center {
    text-align: center;
  }

  tr > td:last-of-type {
    text-align: right;
  }
}

@media print {
}
