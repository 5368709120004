@import '../../../../components/config';

.users-container {
  padding: 5 10px;
  font-family: "Poppins";
  

  #userSearch{
    margin-right: 0;

    @include md{
      margin: auto 0;
      width: 100%;
    }
  }

  .roundButton{
    margin-left: auto;
  }

  .userTable {
    // padding: 0 20px;
    font-family: 'Poppins';
    margin: 20px 0;
    border-radius: 10px;

    .permissionsCell{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }

    .buttonUserAction {
      // color: $app-red;
      background-color: transparent;
      border: none;
    }
  }

  @include md{
    .userHeader h1{
      font-size: 28px;
    }

    .userSubHeader h3{
      font-size: 24px;
      padding: 0;
      margin: auto 0;
    }
  }
  
}

.buttonUserAction {
  // color: $app-red;
  background-color: transparent;
  border: none;
}

.userCard{
  width: 80%;
  margin: 0 auto;
  padding: 25px;

  input {
    background-color: #F9F6F6;
  }

  h1{
    text-align: center;
  }
  
  p{margin: 0 0 5px 0;}

  .userLabelContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .userLabel {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: $app-red;
  }
  @include md {
    .userLabelContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @include md {
    width: 100%;
    font-size: 10px;

    h1{
      font-size: 35px;
    }

  }
}


.roundButton.MuiButtonBase-root{

  span.text{
    font-family: Poppins;
    text-transform: none;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.015em;
  }
}

.permission-field{
  position: relative;

  input{
    padding: 15px;
    padding-right: 80px;
  }

  span{
      position: absolute;
      top: 15px;
      right: 20px;
      color: $app-red;
      cursor: pointer;
      display: block;
  }
}


.userPermissionsCard{
  // width: 600px;
  padding: 20px 0;
  // overflow: auto;
  // max-height: 100vh;
  // box-sizing: border-box;

  .modalHeader{
      display: flex;
      justify-content: space-between;
      text-align: unset;
      padding: 0px 20px 0 20px !important;
      
      // h2{
      //   font-weight: bold;

      // }

      .roundButton{
          svg {
              // display: inline-block;
              color: $app-red;

          }
      }
  }

  .modalFooter{
    margin-top: 20px;
  }

  @include md{
      .modalHeader {
          h2 {
              font-size: 15px;
              margin: auto 0;
          }

          button{
              font-size: 10px;
          }
      }


  }
}

.permissionsList{
  // width: 100%;
  font-size: 10px;

  .MuiListItemText-root span{
    font-family: "Poppins"
  }
  
  

  &>.MuiListItem-root{
      background: rgba(196, 196, 196, 0.1);
      border-bottom: 1px solid #ddd;
      padding-left: 25px;

      svg{
          visibility: hidden;
      }
      &:hover>svg{
          visibility: visible;
      }
  }

  .nested{
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 30px;
      background: #fff;
      border-bottom: 1px solid #ddd;

      &>.MuiListItemText-root span{
        transform: translateX(40px);
        font-family: "Poppins"
      }
      

      svg{
          // width: 15px;
          // height: 15px;
      }
  }

  @include md(){
    .MuiListItemText-root span{
      font-size: 10px;
    }
  }
}

.selectedPermissions{
  margin-top: 40px;
  border-radius: 9px;

  h3 {
    background: $app-red;
    padding: 12px 15px;
    color: #fff;
    // font-weight: bold;
  }

  p {
    color: #8A8A8A;
    padding: 20px;
    margin: 0;
  }

  @include md(){
    font-size: 10px;
    margin-top: 10px;

    h3{
      font-size: 15px;
    }
  }
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.view-more-container{
  display: inline-block;
  /* white-space: nowrap; */
  overflow: hidden;
}

.root-radios-user-status {
  padding-left: 20px;
}

.root-radios-user-status .MuiFormControlLabel-label {
  /* Button 2 */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  /* Gray Title */
  color: #8A8A8A;
}

.status-active {
  color: #F7A458;
}

.status-blocked {
  color: $app-red;
}

.capitalize {
  text-transform: capitalize;
}