@import "../../config";

.news-module-wrapper .admin-base {
  .section-left a {
    display: flex;
    align-items: center;
  }
  .toolbar-left {
    display: flex;
    align-items: flex-start;
  }
}

.news-date-range-dialog {
  .MuiDialog-paper {
    margin: 0px;
  }
}



@include lg{
  .NewsTable{
    margin: 20px;
  }
}

.news-filter {
  width: 100%;
  text-align: end;
}