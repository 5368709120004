#home {
  display: flex;
  justify-content: space-around;
  align-content: center;
  font-size: 2rem;
}

.diseases-screen-title{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #323232;
}

.background-single-disease {
  background-color: #B22028;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
}
.disease-card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  margin: 12px 16px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: flex-start;
}
.edit-delete-disease {
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  left: 87%;
  top: 200px;
}
.single-disease {
  position: relative;
  display: block;
  min-height: 231px;
  color: #fff;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 20px;

  a {
    display: flex;
    justify-content: flex-start;
    background-color: #B22028;
    height: 30%;
    align-items: flex-start;
    transition: 0.5s;
    border-radius: 0px 0px 6px 6px;
  }
}

.diseaseOptionsLink {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
}

.diseaseOptionsLink:hover {
  color: #fff;
}

.skeleton-p {
  span {
    span {
      margin: 1%;
    }
  }
}

.card-container {
  position: relative;
  display: block;
  z-index: 0;

  &:hover {
    background: #8c8c8c;
  }

  &:hover > .cardContent {
    background: #8c2626;
  }

  &:hover > .optionsButton {
    visibility: visible;
  }

  &:hover > .optionsButton:hover {
    background: white;
  }
}

.cardContent {
  background: #AE2F2F
}
.single-disease:hover > a {
  background: #7F1E22;
}
.optionsButton {
  visibility: hidden;
}
.single-disease:hover > .edit-delete-disease {
  visibility: visible;
}