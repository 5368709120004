@import '../../config';

.changePassword{
    // temporary structure issue fix
    margin: -24px;

    background-size: cover;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    
    section {
        margin: auto 0;
    }
}

.pageHeader {
    background-color: #fff;
    padding: 15px 20px;

    // temporary structure issue fix for mobile devices
    @include lg {
        margin: 15px;
    }

    h1 {
        margin: 0;
        display: inline-block;
        cursor: pointer;
        svg { transform: translate(0, -3px); }
    }
}

.cpContent{
    width: 310px;
    margin: 20px auto 0 auto;
    color: $app-title-gray;

    .cpTitle{
        display: flex;
        justify-content: space-between;

        h2{
            margin: auto 0;
        }
    }

    .cpForm{
        width: 240px;
        margin: 25px auto;

        label {
            margin: 0.7rem 0 0 0;
        }

        .cpAction {
            margin-top: 25px;
            display: flex;
            justify-content: space-evenly;
        }
    }

    .auth-inputs {
        margin: 10px 0;
        background: $app-bg;
        transition: 0.3s ease-in;
    
        fieldset {
          display: none;
        }

      }
}

button.btnFilled {
    background-color: $app-red;
    color: white;
    font-weight: 600;
    
    &:hover{
        background-color: $app-red;
    }
}

