.skeleton-p {
  span {
    span {
      margin: 1%;
    }
  }
}
.ta-screen-title{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #323232;
}
.optionsContainer {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
}
.optionsLink:hover {
  color: #B22028;
}
.addTherapAreaTitle {
  font-weight: 600;
  font-family: Poppins;
}
.optionTag {
  font-family: Poppins;
  margin-left: 10px;
}
.optionsPopover {
  padding: 5px;
  max-width: 220px;
}
.card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  margin-top: 12px;
  width: 208px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.background-single-category {
  background-color: #B22028;
  border-radius: 0px 0px 6px 6px;
}
.edit-delete {
  cursor: pointer;
  visibility: hidden;
  position: absolute;
  left: 87%;
  top: 26px;
}
.single-category {
  position: relative;
  display: block;
  min-height: 231px;
  color: #fff;
  box-shadow: 0px 24px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin: 20px;
  cursor: pointer;

  .catActions {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    justify-content: center;
    transition: 0.4s ease-in;
  }

  a {
    display: flex;
    justify-content: center;
    background-color: #B22028;
    height: 30%;
    align-items: center;
    transition: 0.5s;
    border-radius: 6px 6px 0px 0px;

    h4 {
      border: 2px solid #fff;
      border-color: transparent;
      padding: 15px;
      transition: 0.4s;
    }

    &:hover {
      color: inherit;

      h4 {
        border-color: #fff;
      }
    }
  }
}

.single-category:hover > a {
  background: #7F1E22;
}
.single-category:hover > .background-single-category {
  background: black;
  text-decoration: underline white;
}
.single-category:hover > .edit-delete {
  visibility: visible;
}