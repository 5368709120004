@import './components/config';

// According to Design Guide

// Buttons
.atButton {
  border: none;
  border-radius: 3px;
  padding: 8px 25px;
  // border: 2px solid $app-red;
  color: $app-red;
  background-color: #fff;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
  transition: 0.3s ease-in-out;
  white-space: nowrap;


  &:hover {
    background-color: darken(white, 4%);
  }

  &:focus {
    filter: brightness(90%);
  }

  svg{
      display: inline-block;
  }

  &.fill {
      color: white;
      background-color: $app-red;

      &:hover {
        background-color: darken($app-red, 5%);
      }
      
  }

  &.bordered {
    border: 2px solid $app-red;
  }

  &.medium {
    padding: 7px 23px;
  }

  &.small {
    padding: 5px 20px;
  }
}


