.dropzone-container {
  margin: 10px;

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:focus {
      border-color: #2196f3;
    }
  }

  .image-preview {
    margin: 10px;
    div {
      display: inline-block;
      position: relative;

      img {
        max-width: 200px;
      }
    }

    .delete-button {
      margin-left: 10px;
    }
  }
}

#categoryInput {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .categoryFormControl {
    width: calc(100% - 55px);
    text-align: left;
  }

  .addButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
