@import '../../config';

.admin-base {
    .toolbar-right{        
        justify-content: flex-end;
        
        .search-container{
            margin: auto 0 auto 20px;
            width: 70%;
        }
    }
}

a.menu{
    margin: auto 5px;

    &.active{
        button{
            background-color: $app-red;
            color: white;
        }
    }
}

.buttons-container {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
}

.combo-boxes { width: 100% }

.form-title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    color: #8A8A8A
}

.form-icon-image { margin-right: 15px }

.form-icon-title-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

a.generic_table_logos img{
    max-width: 50px;
}