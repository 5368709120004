@import "../config";

#report-6 {

  #toImage{
    margin-bottom: 20px;
    font-size: 16px;
    padding: 20px;
  }

  table {
    width: 100%;
    // width: fit-content;
    font-size: 1.15em;
    border: 0px;
    // margin-top: 10px;

    th {
      border-left: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      // font-size: 11px;
      height: 50px;
      text-align: center;
      // width: 16.6%;
      // width: fit-content;
      color: #fff;
      background-color: $thead-bg-color;
      padding: 5px 15px;

      &:first-child {
        border-left: 0px;
        background-color: transparent;
      }
      &:last-child {
        border-right: 0px;
      }
    }

    .spacer{
      height: 10px;
      td{vertical-align: middle;}

      div{
        border-bottom: dashed red;
        border-width: 2px;
        // position: absolute;
        width: 100%;
        margin-top: 10px;
      }

      &:last-child div{
        display: none;
      }
    }

    td {
      vertical-align: top;
    }

    td.lot-row{
      vertical-align: middle;
      // height: 100%;
      border: 1px solid black;
      width: 5px; // Added by Abhishek on request of team.

      .holder{
        width: 35px;
        justify-content: center;
      }

      // div{
      //   flex-direction: column;
      // }
      span{
        display: inline-block;
        transform: rotate(-90deg);
        font-size: 1.3rem;
        font-weight: bold;
      }
      
    }
  }

  .holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .box {
      background-color: #efefef;
      width: 185px;
      display: flex;
      resize: horizontal;
      overflow: hidden;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 5px;
      // @include boxShadow();
      box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49);
      border: 1px solid rgba(132, 127, 127, 0.49);

      &.withBorder {
        border: 1px solid orange;
      }

      .box-texts {
        word-break: break-word;
        width: 100%;

        img {
          width: 25px;
          height: 25px;
          display: block;
          float: right;
          clear: both;
          margin: 2px -8px 2px 5px;
        }
      }

      strong {
        line-height: 1.4rem;
        margin-bottom: 3px;
        display: flex;

        .footnotesIndex {
          font-weight: normal;
          // color: red;
          font-size: 0.9em;
          margin-top: -2px;
        }
      }
      p:first-of-type {
        font-style: italic;
        strong {
          display: inline;
        }
      }
      p {
        line-height: 1.3rem;
        margin: 3px 0;
        font-size: 1.1em;
      }
      .badge {
        margin: 0 5px 5px 0;
        font-weight: bold;
        white-space: unset;
        text-align: left;
        display: inline-block;
      }

      .tm-container {
        width: 40px;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-end;
        // justify-content: space-between;
        margin-left: auto;
        img {
          margin: 5px -8px 5px 0;
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .left-behind {
    table {
      th {
        font-size: 0.8rem;
      }
      td {
        padding: 0;
      }
    }
  }
}

.biomarkers-logo{
  // background-color: $app-gray;
  background-color: #266685;
  border-radius: 50%;
  padding: 5px;
}

.logo-legends {
  img{
    width: 40px;
    height: 40px;
  }
  .legendText{
    margin: auto 0 auto 5px;
    text-transform: capitalize;
  }
}



.colorBox{
  margin: 10px;
  padding: 15px;
  display: flex;
  justify-content: center;

  .legendText{
    color: white;
    margin: auto 0;
  }
}

@media print {
  .controls {
    display: none !important;
  }
  .badge {
    border: none;
  }
  #report-6 {
    thead {
      break-inside: auto;
    }
  }
  .holder .box {
    resize: unset !important;
  }
}
