@import '../config';

$modal-padding: 25px;

.modalHeader{
    text-align: center;
    position: relative;
    color: $app-red;

    @include md(){

        h1{
            font-size: 15px;
        }
    }

    &>svg{
        display: none;
        position: absolute;
        right: 15px;
        top: 5px;
        background: $app-red;
        border-radius: 50%;
        color: white;
        padding: 3px;
    }

    &.withClose{
        svg{
            display: block;
        }
    }
}

.modalHeader[disabled]  {
    &>svg{
        display: inline;
        position: absolute;
        right: 15px;
        top: 5px;
        color: white;
        background: rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        color: white;
        padding: 3px;
    }
}

.screen-icon-modal{
    display: flex;
    justify-content: center;
    align-items: center
}

b.atacana-label{
    margin-bottom: 5px;
    display: block;
}

input.atacana, select.atacana{
    display: block;
    width: 100%;
    padding: 10px;
    background: #F9F6F6;
    border: 1px solid #DDDADA;
    box-sizing: border-box;
    font-size: 15px;
    border-radius: 4px;
    // margin-bottom: 10px;
}

.modalFooter{
    display: flex;
    margin-top: 20px;
    justify-content: center;
    button{
        margin: 0 10px;
    }

    @include md(){
        button {
            padding: 5px 10px;
            font-size: 10px;
        }
    }
}


.userInformationCard{
    width: 600px;
    padding: $modal-padding;

    @include md(){
        font-size: 10px;
    }
}

.permissionsInformationCard{
    width: 600px;
    padding: $modal-padding;

    @include md(){
        font-size: 10px;
    }
}


.deleteConfirmCard, .userUndoCard{
    width: 550px;
    padding: $modal-padding;
    font-family: 'Poppins';

    .modalHeader {
        color: initial;

        img {
            margin-bottom: 10px;
        }
    }

    b{
        text-align: center;
        display: block;
        font-size: 16px;
    }

    h3{
        text-align: center;
        color: initial;
    }
}

.modalButton {
    border: none;
    border-radius: 3px;
    padding: 8px 25px;
    border: 2px solid $app-red;
    color: $app-red;
    background-color: inherit;
    text-transform: uppercase;
    line-height: initial;
    font-weight: 600;

    svg{
        display: inline-block;
    }

    &.fill {
        color: white;
        background-color: $app-red;
    }
}

.fullName {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $app-red;
}

.text-center {
    text-align: center;
}

.containerDateRange {
    overflow: hidden;
    position: absolute;
    z-index: 1000;
    transform: translate(5%, 60px);
    box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}