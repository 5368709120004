@import '../../config';

  .ta-screen-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #323232;
}

.client-subheader{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding: 10px;
    margin: 0 0 0 5px;
}

  .optionsContainer {
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
  }
  .optionsLink:hover {
    color: #B22028;
  }

  .optionTag {
    font-family: Poppins;
    margin-left: 10px;
  }
  .optionsPopover {
    padding: 5px;
    max-width: 220px;
  }
  .card-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-transform: capitalize;
    margin-top: 12px;
    width: 208px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

.single-client {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 231px;
    //max-height: 263px;
    min-width: 240px;
    max-width: 240px;

    color: #fff;
    box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: 20px;
    cursor: pointer;
    background-color: #4D4F54;

    &:hover{

    }
    
    .client-info{
        margin-top: auto;
        border-radius: 0px 0px 6px 6px;
        background-color: $app-light-gray;
        padding: 10px;
        font-family: 'Poppins';
    }
  }
