@import "../config";



.header-title {
  border-left: #c00000;
  border-left-style: outset;
  border-width: thick;
  padding-left: 2.5rem;
  height: 5rem;
  padding-top: 1rem;
  border-left-width: 10px;
  /* margin-bottom: 0; */

  h1,
  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 2rem;
  }
  h2 {
    font-size: 1.5rem;
  }
}

#report-2 {
  .table-container {
    overflow-x: scroll;
  }

  $cell_margin: 2px;

  .gridTable {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: min-content minmax(auto, auto);
    grid-column-gap: 5px;

    .sideBar {
      background-color: red;
      border-bottom: 2px solid #ddd;
      position: relative;
      color: #fff;
      line-height: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      @include boxShadow();

      p {
        margin: 10px 0;
        text-orientation: sideways;
        // writing-mode: vertical-rl;
        transform: rotate(270deg);
        // display: inline-block;
        width: min-content;
        // min-width: min-content;
        // max-width: max-content;
        text-align: center;
        word-break: break-word;
        // white-space:;
        // min-width: 100px;
        // resize: horizontal;
        // overflow: hidden;


        // span {
        //   display: block;
        //   -webkit-transform: rotate(-90deg);
        //   transform: rotate(-90deg);
        //   line-height: 0.7rem;
        // }
      }
    }

    .gtHeaders {
      display: flex;
      color: #fff;

      & > div {
        width: stretch;
        text-align: center;
        padding: 1rem;
        margin: $cell_margin;
        // border: 3px solid #fff;
        background-color: #3f3f3f;
        font-size: 1.01em;
        font-weight: bold;
      }
    }

    .segments {
      border-bottom: 1px solid #ddd;
      resize: vertical;
      overflow: hidden;
      // direction: ltr;
    }

    .gtRow {
      display: flex;
      width: stretch;
      padding-bottom: 5px;

      & > div {
        overflow: visible;
        // width: 12.5%;
        padding: $cell_margin;

        .info {
          display: flex;
          resize: horizontal;
          overflow: hidden;
          min-height: 45px;
          // min-width: 80px;
          width: max-content;
          max-width: max-content;
          // width: 40px;
          word-break: break-all;

          // align-items: center;

          .marker-container {
            padding: 3px;
          }

          p {
            margin: 0;
            // float: left;
            text-align: left;
            margin-left: 5px;
            font-weight: bold;
            // font-size: 0.95rem;

            .product-company {
              color: #000;
              display: block;
              width: 100%;

              .company-name {
                color: inherit;
                // display: block;
              }
            }

            span {
              // white-space: nowrap;
              font-size: 0.99em;
              color: gray;
              // white-space: nowrap;
            }
          }
        }

        @for $i from 0 through 11 {
          &.quarter-#{$i} {
            .info {
              margin-left: calc(
                (100% / 13) * #{$i}
              ); // 14 for extra two space for the corner case
            }
          }
        }

        // Left Alignment
        &.toLeft {
          direction: rtl;
          .info {
            margin-left: 0 !important;
            min-width: 100px;

            p {
              text-align: right;
              margin-right: 5px;
              margin-left: 0px;
            }
          }

          @for $i from 0 through 11 {
            &.quarter-#{$i} {
              .info {
                margin-right: calc(
                  (100% / 13) * (11 - #{$i})
                ); // 14 for extra two space for the corner case
              }
            }
          }
        }
      }
    }
  }

  .left-behind {
    th:nth-child(1) {
      width: 10%;
      border-right: 3px solid #fff;
      background: unset;
    }
  }

  .ns-therapy {
    position: absolute;
    top: 1px;
    left: calc(100% + 2px);
    width: 50px;
    height: 40%;
    background-color: red;
    @include boxShadow();
    display: none;

    span {
      display: inline-block;
      width: 130%;
      transform: rotateZ(-90deg);
      position: absolute;
      top: 35px;
      word-break: break-all;
      text-align: center;
    }

    &::before {
      content: "";
      position: absolute;
      // height: 100%;
      border-top: 50px solid transparent;
      border-left: 50px solid red;
      border-bottom: 50px solid transparent;
      left: 100%;
    }

    &.therapy-1 {
    }

    &.therapy-2 {
      top: calc(40% + 3px);
    }
  }

  $mSize: 25px;
  .marker {
    width: $mSize;
    height: $mSize;
    transform: rotate(50deg) skew(15deg);
    // -webkit-transform: rotateY(35deg) rotateZ(45deg);
    // transform: rotate rotateZ(45deg);
    display: inline-block;
    // @include boxShadow();
    min-width: 25px;
    margin-top: 5px;

    &.bordered {
      border: 3px solid red;
      border-left-color: red !important;
    }

    &.cutomFormat {
      position: relative;
      margin-right: 21px;

      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 2px;
        top: -8px;
        right: -17px;
        transform: rotateY(0deg) rotateZ(-45deg);
        background-color: inherit;
      }

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -23px;
        right: -25px;
        transform: rotateY(0deg) rotateZ(-45deg);
        // background-color: inherit;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid transparent;
        border-left: 12px solid transparent;
        border-left-color: inherit;
      }
    }
  }

  .legend {
    // margin-top: 5px;
    display: flex;
    align-content: center;

    .marker {
      margin: auto 0;
    }

    .legendText {
      margin: auto 0;
      
        // font-size: 0.9rem;
        margin-left: 10px;
      
    }
  }

  #marker-specs {
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .marker-spec {
      display: flex;
      width: fit-content;
      align-items: center;

      div {
        margin-left: 15px;
        p {
          margin: 0;
        }
      }
    }
  }

  .bg-blue {
    background-color: blue;
  }

  .bg-red {
    background-color: red;
  }
  .bg-green {
    background-color: #39b050;
  }

  .bg-yellow {
    background-color: #f9c102;
  }

  .bg-darkred {
    background-color: #c5103d;
  }
  .bg-sky {
    background-color: #3fb0ef;
  }
  .bg-violet {
    background-color: #ac96ee;
  }
  .bg-purple {
    background-color: #7030a0;
  }
  .bg-gray {
    background-color: #efefef;
  }

  td.center {
    text-align: center;
  }

  tr > td:last-of-type {
    text-align: right;
  }
  .actionBtn {
    display: flexbox;
    text-align: right;
  }
  .actionBtn div {
    display: inline-flex;
  }
  .filterContainer {
    margin-left: 10px;
  }
}

@media print {
  .filterContainer {
    display: none !important;
  }
}
