// @import "../config";
#toImage {
  font-size: 18px;
  line-height: 20px;
}

.export {
  display: flex;
  justify-content: flex-end;
  align-content: center;

  button {
    font-weight: 600;
  }

  .form-group {
    width: 200px;
    margin: auto 10px;
  }
  .export-image {
    // background-color: #002060;
    color: white;
    border-style: none;
    height: 3rem;
    width: 10rem;
    // float: right;
    // position: absolute;
    // top: 10px;
    // right: 10px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: medium;
  }

  
}
