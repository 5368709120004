.sources-wrapper-draftnews{
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;
}
.visibility-icon-draftnews{
  margin-bottom: 8px
}
.visibility-switch-rapper{
  display: flex;
  align-items: center; 
  flex-direction: row
}
.actionstate-headertable-wapper{
  display: flex;
  justify-content: space-around
}
.link-icon-draftnews{
  transform: rotate(-45deg);
  font-size: 16px
}
.src-keyword-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-start
}
.edit-delete-btn-wrapper{
  display: flex;
  flex-direction: row
}
.source-name-draftnews{
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #B22028;
  text-decoration: underline;
}
.sources-legend{
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4D4F54;
  margin: 0 10px;
}
.sourcelink-icon-wrapper{
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-wrapper{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}