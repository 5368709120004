@import "../config";

#report-5 {
  .admin-base {
    hr, .section-left a {display: none;}
    
    .section-header{
      height: 0;
      padding: 0;
    }
    // .layout-toolbar{padding: 0;}

    .section-right{
      transform: translateY(-155%);
    }
  }

  .no-data {
    padding: 20px;
    text-align: center;
    font-style: italic;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  #newsGlobalTitle{
    display: none;
  }

  .news_filters {
    // #indication-mutiple-checkbox-label,
    // #indication-mutiple-checkbox #indication {
    //   display: none;
    // }
    div.indication * {
      display: none;
    }
    // & > div:nth-child(3) {
    //   display: none;
    // }
  }


  #dateRangeContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

@media print {
}
