@import "../config";

#report-1-tm {
  table {
    width: 100%;
    // width: fit-content;
    font-size: 1.15em;
    border: 0px;
    margin-top: 10px;

    th {
      border-left: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      // font-size: 11px;
      height: 50px;
      text-align: center;
      // width: 16.6%;
      // width: fit-content;
      color: #fff;
      background-color: $thead-bg-color;
      padding: 5px 15px;

      &:first-child {
        border-left: 0px;
      }
      &:last-child {
        border-right: 0px;
      }
    }

    td {
      vertical-align: top;
    }
  }

  .holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .box {
      background-color: #efefef;
      width: 185px;
      display: flex;
      resize: horizontal;
      overflow: hidden;
      box-sizing: border-box;
      padding: 10px;
      border-radius: 5px;
      margin: 10px 5px;
      // @include boxShadow();
      box-shadow: 1px 1px 1px 0px rgba(132, 127, 127, 0.49);

      .box-texts {
        word-break: break-word;
      }

      strong {
        line-height: 1.4rem;
        margin-bottom: 3px;
        display: flex;

        .footnotesIndex {
          font-weight: normal;
          // color: red;
          font-size: 0.9em;
          margin-top: -2px;
        }
      }
      p:first-of-type {
        font-style: italic;
      }
      p {
        line-height: 1.3rem;
        margin: 3px 0;
        font-size: 1.1em;
      }
      .badge {
        // color: red;
        font-weight: bold;
        white-space: unset;
        text-align: left;
        display: inline-block;
      }

      .tm-container {
        width: 40px;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-end;
        // justify-content: space-between;
        img {
          margin: 5px -8px 5px 0;
          width: 25px;
        }
      }
    }
  }

  .left-behind {
    table {
      th {
        font-size: 0.8rem;
      }
      td {
        padding: 0;
      }
    }
  }
}

@media print {
  .controls {
    display: none !important;
  }
  .badge {
    border: none;
  }
  #report-1-tm {
    thead {
      break-inside: auto;
    }
  }
  .holder .box {
    resize: unset !important;
  }
}
