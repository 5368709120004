@font-face {
    font-family: PoppinsSemiBold;
    src: url("../../../assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

.listItemText {
font-family: PoppinsSemiBold;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #CCC3C3;
    margin-right: 10px;
}

.listItemSelected {
    color: #b22028;
}

.listItemSelected .dot{
    background: #b22028;
}

.drawerTooltip {
    font-size: 2em;
    width:auto;
}

.Mui-selected {
    color: #b22028!important;
}

.Mui-selected .dot{
    background: #b22028;
}